import "./ColunaSistemaComponent.scss";
import { Card, Col, Row } from "react-bootstrap";
import { BellsComponent } from "../bells/BellsComponent";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CardPedidoComponent } from "../card-pedido/CardPedidoComponent";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PedidoType } from "../../../../../shared/types/PedidoType";
import { PedidoItemType } from "../../../../../shared/types/ItemPedidoType";

export const ColunaSistemaComponent: React.FC<{ onUpdate: boolean }> = ({ onUpdate }) => {

    const { pedidos } = useSelector((rootReducer: any) => rootReducer.PedidosReducer);

    const [todosOsPedidos, setTodosOsPedidos] = useState(pedidos);
    const [selectedFruit, setSelectedFruit] = useState('todos');

    useEffect(() => {
        if (selectedFruit === 'coxinha') {
            setTodosOsPedidos(pedidos.filter((pedido: PedidoType) => pedido.items.find((item: PedidoItemType) => item._id.descricao === 'Coxinha')))
        } else {
            setTodosOsPedidos(pedidos)
        }

    }, [selectedFruit, pedidos, setTodosOsPedidos]);

    const nodeRef = React.useRef(null);

    const limit: number = 1000;
    const limiteVisivel = 3;

    const isNext = (pedidos.length - limiteVisivel) > 0

    function countItemDescricao(descricao: string): number {
        return pedidos.reduce((soma: number, pedido: PedidoType) => {
            return soma + (pedido.items.some(item => item._id.descricao === descricao) ? 1 : 0);
        }, 0);
    }

    return (
        <Card className="h-100_ border-0 p-2 bg-transparent" style={{ height: '100vh' }}>
            <Row className={`h-auto bg-primary bg-opacity-50 shadow rounded`}>
                <Col className="col-10 col-md-10 p-3">
                    {isNext ? <Row>

                        <Col>
                            <ul className="list-group">
                                <li className="list-group-item bg-transparent p-0 border-0">
                                    <label className="position-relative w-auto bg-white rounded px-2 py-1 fw-semibold">
                                        Pedidos em andamento
                                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success fs-5 shadow">
                                            {pedidos.length}
                                            <span className="visually-hidden">
                                                {pedidos.length}
                                            </span>
                                        </span>
                                    </label>
                                </li>
                                <li className="list-group-item bg-transparent p-0 border-0 mt-2">
                                    <label className="position-relative w-auto bg-white rounded px-2 py-1 fw-semibold">
                                        Pedidos com coxinha
                                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning text-black fs-5 shadow">
                                            {countItemDescricao('Coxinha')}
                                            <span className="visually-hidden">
                                                {countItemDescricao('Coxinha')}
                                            </span>
                                        </span>
                                    </label>
                                </li>
                            </ul>
                        </Col>
                        <Col className="bg-white rounded">
                            <div className="form-group">
                                <label className="fs-small fw-semibold ms-1">Mostrar</label>
                                <select className="form-select bg-secondary bg-opacity-25 border-0"
                                    name="selectedFruit" value={selectedFruit}
                                    onChange={e => setSelectedFruit(e.target.value)}>
                                    <option className="fw-medium" value="todos">Todos os pedidos</option>
                                    <option className="fw-medium" value="coxinha">Pedidos com coxinha</option>
                                </select>
                            </div>
                        </Col>
                    </Row> : null}
                </Col>
                <Col className="col-2 col-md-2 p-3">
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <BellsComponent ring={onUpdate}></BellsComponent>
                        </Col>
                    </Row>

                </Col>
            </Row>
            <TransitionGroup component={Row} className="h-100 d-block overflow-auto" noderef={nodeRef}>
                {todosOsPedidos.slice(limiteVisivel, limit).map((pedido: PedidoType, index: number) => (
                    <CSSTransition
                        key={index}
                        classNames="fade"
                        timeout={500}
                        ref={nodeRef}
                    >
                        <Col className="col-12">
                            <CardPedidoComponent pedido={pedido} />
                        </Col>
                    </CSSTransition>
                ))}
            </TransitionGroup>
        </Card>
    );
};